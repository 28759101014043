// 认证配置和当前表单做比较
export function buildFormByAuthModeFields(formData, authModeFields) {
  const keys = Object.keys(formData);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (!authModeFields[key]) {
      formData[key].hidden = true;
    } else {
      formData[key].hidden = false;
    }
  }
}

function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * 以第一个对象为基准，去比较两个对象
 * @param {*} source
 * @param {*} target
 * @returns
 */
export const objectCompare = (source, target) => {
  if (Array.isArray(source) && Array.isArray(target)) {
    if (source.length !== target.length) return false;
    for (let i = 0; i < source.length; i++) {
      if (!objectCompare(source[i], target[i])) return false;
    }
    return true;
  }
  if (isObject(source) && isObject(target)) {
    const keys = Object.keys(source);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (!objectCompare(source[key], target[key])) return false;
    }
    return true;
  }
  return source === target;
};
