import ModeEnum from './ModeEnum';
export default class Driver {
  constructor() {
    this.authMode = null;
  }

  _minimalMode() {
    return {
      mode: this.authMode,
      name: true,
      idCard: true,
      driverPhone: true
    };
  }

  _mediumMode() {
    return {
      ...this._minimalMode(),
      idCardStartDate: true,
      driveType: true, // 准驾车型
      driverLicenseEndDate: true // 驾驶证有效期
    };
  }

  _completeMode() {
    return {
      ...this._minimalMode(),
      ...this._mediumMode(),
      driverLicenseIssueOrganization: true, // 驾驶证发证机关
      qualificationNumber: true, // 从业资格证号码
      driverLicenseNumber: true, // 驾驶证编号
      address: true,
      idCardIssueOrganization: true, //签发机关
      qualificationLicenceEndDate: true,
      driverLicenseAnnualReviewDate: true,
      qualificationLicenseIntegrityAssessmentValidTo: true,
      qualificationLicenseIntegrityAssessmentRecord: true,
      fileNumber: true,
      driverLicenseInitialIssueDate: true,
      driverLicenseAddress: true,
      qualificationLicenseType: true, //
      qualificationLicenseDriveType: true, //从业资格证准驾车型
      qualificationLicenseAddress: true, //从业资格证住址
      qualificationLicenseInitialIssueDate: true, //从业资格证初次领证日期
      qualificationLicenseIssueOrganization: true, //从业资格证初次发证机关
      continuingEducationLevel: true,
      continuingEducationEndDate: true
    };
  }

  build(authMode) {
    this.authMode = authMode;
    if (authMode === ModeEnum.SIMPLE) return this._minimalMode();
    if (authMode === ModeEnum.MEDIUM) return this._mediumMode();
    return this._completeMode();
  }
}
