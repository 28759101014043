<template>
  <div class="drivers-content">
    <!-- 导航栏 -->
    <NavBar title="司机认证" @clickLeft="handleRouterBack" :customClickLeft="true" :leftArrow="ifShowLeftArrow">
    </NavBar>
    <div class="bg-white h-full overflow-scroll">
      <!-- 进度条 -->
      <TosbStep class="step pa-16 pb-0" :steps="steps" :current="step" />

      <!-- 步骤一 -->
      <div class="pt-34">
        <component v-if="step > -1 && allLoading" :is="currentStepComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import NavBar from '@/components/NavBar/index.vue';
import TosbStep from '@/components/Step/index.vue';
import { computed, defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { Button, Toast, Dialog } from 'vant';
import { useDriverVerifiedStore } from '@/store/driverVerfied';
import { useUserStore } from '@/store/user';
import { useRouterQuery } from '@hook/useRouterQuery';

import { steps } from './constants';
import { PageEnum } from '@/enums/pageEnum';

import AuthModeFactory from '@/services/AuthenticationMode/AuthModeFactory';
import Driver from '@/services/AuthenticationMode/Driver';
import { getPlatformAppAuditModeApi } from '@/api/common';
import ModeEnum from '@/services/AuthenticationMode/ModeEnum';

const authModeFactory = new AuthModeFactory(new Driver());

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    NavBar,
    TosbStep,
    Step1: () => import('./components/step1'),
    Step2: () => import('./components/step2'),
    Step3: () => import('./components/step3'),
    Step4: () => import('./components/step4'),
    Button
  },
  setup(_, ctx) {
    const userVerifiedStore = useDriverVerifiedStore();
    const ifShowLeftArrow = ref(true);
    const allLoading = ref(false);
    const query = useRouterQuery();
    // 当前进度条进度响应到store中的数据
    const step = computed(() => userVerifiedStore.verifiedInfo.step);

    // 判断当前显示步骤几的组件
    const currentStepComponent = computed(() => {
      return `Step${step.value + 1}`;
    });

    const userStore = useUserStore();
    const driverAuthInfo = computed(() => userStore.driverAuthVO);

    // 默认完整模式
    userVerifiedStore.updateAuthModeFields(authModeFactory.build(ModeEnum.INTEGRITY));
    // userVerifiedStore.isAmendDriverAuthInfo = false;

    const setAuthMode = async () => {
      try {
        const res = await getPlatformAppAuditModeApi();
        userVerifiedStore.updateAuthModeFields(authModeFactory.build(res.data));
      } catch (error) {
        Toast.fail(error);
      }
    };

    onBeforeMount(async () => {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '加载中...'
      });

      const userInfo = await userStore.getUserInfoAction();
      userVerifiedStore.getApolloConfigAction();
      // debugger;
      // 第一次进行 车辆认证 需要分层
      if (!userVerifiedStore.showComplete && !driverAuthInfo.value) {
        await setAuthMode();
      }

      // 编辑的时候防止白屏
      if (query.isEdit) {
        userVerifiedStore.updateInfo({
          step: 0,
          authStatus: -1 // 需要将状态值重置为 -1
        });
      }

      userVerifiedStore.queryVerifiedInfo(
        // 初始化获取详情 如果是列表中进来的重定向到结果页
        () => {
          setTimeout(() => {
            Toast.clear();
          }, 500);
          allLoading.value = true;
          // 编辑状态初始化的时候需要设置step为0
          query.isEdit &&
            userVerifiedStore.updateInfo({
              step: 0,
              authStatus: -1 // 需要将状态值重置为 -1
            });
        },
        () => {
          // 编辑的状态不跳转
        }
      );
    });

    return {
      currentStepComponent,
      steps,
      step,
      allLoading,
      ifShowLeftArrow,
      handleRouterBack: () => {
        // 如果状态为【待审核】或者【已审核】，返回回到上一页
        if (step.value === 0) {
          Dialog.confirm({
            message: '信息暂未提交认证，退出后信息不会保存，是否继续操作!'
          })
            .then(() => {
              ctx.root.$router.push(PageEnum.BASE_MINE);
            })
            .catch(() => {});
        } else {
          if ([3].includes(step.value)) {
            ctx.root.$router.push(PageEnum.BASE_MINE);
          } else
            userVerifiedStore.updateInfo({
              step: step.value - 1
            });
        }
      }
    };
  }
});
</script>
<style lang="less" scoped>
@import url('./styles/index.less');
.drivers-content {
  min-height: 100vh;
  background: #fff;
}
</style>
<style lang="less">
.titleError {
  color: #ee0a24;
  .van-field__label {
    color: #ee0a24;
  }
}
</style>
