import { http } from '../utils/http';
let path;

path = process.env.VUE_APP_PATH + '/resource';

// OCR身份证识别
export const ocrIdCardApi = async data => {
  return await http['post'](`${path}/thirdparty/ocr/idCard`, data, false);
};

path = process.env.VUE_APP_PATH + '/driver-center';

// 获取司机认证信息
export const getDriverAuthInfoApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/getDriverAuthInfo`, data, false);
};
// 申请司机认证
export const driverAuthApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/driverAuth`, data, false);
};

// 更新司机认证状态
export const updateDriverAuthApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/updateDriverAndResubmit`, data, false);
};

// 撤回审核
export const withdrawAuditDriver = async data => {
  return await http['post'](`${path}/driver/mine/driver/withdrawAuditDriver`, data, false);
};

// 查询已撤回信息
export const getDriverAuthInfoById = async data => {
  return await http['post'](`${path}/driver/mine/driver/getDriverAuthInfoById`, data, false);
};

// 获取登陆实名认证信息 主要是获取 生份证日期
export const getRealNameInfoApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/getRealNameInfo`, data, false);
};

// 实名通过后，修改实名信息
export const updateDriverByIdApi = async data => {
  return await http['post'](`${path}/driver/mine/driver/updateDriverById`, data, false);
};

// 审核中重新认证
export const afreshDriverAuthApi = async data => {
  return await http['post'](
    `${process.env.VUE_APP_PATH}/driver-center/driver/mine/driver/afreshDriverAuth`,
    data,
    false
  );
};

export const getDriverApolloApi = data =>
  http.post(`${process.env.VUE_APP_PATH}/resource/open/driverCheckAppSwitch`, data);
