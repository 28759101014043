import ModeEnum from './ModeEnum';
export default class AuthModeFactory {
  constructor(model) {
    this.model = model;
  }

  build(autMode = ModeEnum.INTEGRITY) {
    return Object.freeze(this.model.build(autMode));
  }
}
